import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';

/*
import Home from './pages/home';
import Games from './pages/games';
import Services from './pages/services';
import Support from './pages/support';
import Page404 from './pages/404';

import Stat from './pages/stat';

//user
import UserShared from './pages/user/usershared';
import User from './pages/user/user';
import Verify from './pages/user/verify';
import ResetPassword from './pages/user/resetpassword';
import GPT from './pages/user/gpt';
import SimpleGPT from './pages/user/simplegpt';
import ImageCreation from './pages/user/imagecreation';
import TTS from './pages/user/tts';
import SpeechToSpeech from './pages/user/speechtospeech';
import Enlarge from './pages/user/enlarge';
import AddCredits from './pages/user/addcredits';
import LoraUpload from './pages/user/loraupload';

//text pages
import PaymentSuccess from './pages/paymentsuccess';
import PaymentCancel from './pages/paymentcancel';
import Tos from './pages/tos';
import Privacy from './pages/privacy';
import FreeTTS from './pages/freetts';
import FreeImage from './pages/freeimage';

//samples
import TTSSample from './pages/samples/tts';
import VoiceConvertSample from './pages/samples/voiceconvert';
import ImageCreationSample from './pages/samples/imagecreation';

import Sidenav from './components/sidenav';
import Alert from './components/alert';
import CookieConsent from "react-cookie-consent";

import GalleryWorker from './pages/galleryworker';
*/

import { useEffect, useState } from 'react';

import API from './utils/api';

function App() {
  //call API visit once
  const [visit, setVisit] = useState(false);
  useEffect(() => {
    if (!visit) {
      let currentPath = window.location.pathname;
      if (currentPath.startsWith("/")) {
        currentPath = currentPath.substring(1);
      }
      if (!currentPath || currentPath === "") {
        currentPath = "home";
      }

      API.visit(currentPath);
      setVisit(true);
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: "100pt" }}>
      <h3>Sorry, this service is unavailable in your country due to regulations.</h3>
    </div>
  );
}

export default App;
